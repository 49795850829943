import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationsInEng from './locales/eng/translation.json';
import translationsInFrench from './locales/fr/translation.json';


const resources = {
    eng: {
        translation: translationsInEng
    },
    fr: {
        translation: translationsInFrench
    },
    };

    const DETECTION_OPTIONS = {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage']
    };

    i18n
    .use(initReactI18next) 
    .use(LanguageDetector)
    .init({
        resources,
        detection: DETECTION_OPTIONS,
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false,
        },
        ns: "translation", 
        defaultNS: "translation"
    });

export default i18n;