import Form from 'react-bootstrap/Form';
import './CommonForm.css';

import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import InvitationContext from '../../context/InvitationContext.js';
import { InvitationAPI } from '../../../internal/services/InvitationAPI.js';
import { useCookies } from 'react-cookie';
import { InputValidator } from '../../../internal/utils/InputValidator.js';
import { invitationForm } from '../../../internal/utils/InvitationForms.js';


const RespondNowForm = ({ changeActiveForm }) => {
    const { t } = useTranslation();
    const { setInvitation } = useContext(InvitationContext);
    const [, setCookie] = useCookies(['invitationId']);
    const [inputCode, setInputCode] = useState("");

    const [submitError, setSubmitError] = useState(false);

    let errorMessages = {
        invalidInputCode: t("invalidInputCode"),
        notfoundCode: t("notFoundCode"),
        contactUsError: t("contactUsError"),
    }

    const [formFeedBack, setFormFeedBack] = useState(errorMessages.invalidInputCode);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false || !InputValidator.isLenghtValid(inputCode.length)) {
            event.stopPropagation();
            setSubmitError(true)
        } else {
            InvitationAPI.getInvitationById(inputCode)
                .then((resp) => {
                    // Set invitation obj in the context
                    setInvitation(resp)
                    // If we are here it means no cookie were set
                    // TODO create a util for cookie expiration
                    let date = new Date();
                    date.setDate(date.getDate() + 400);
                    setCookie("invitationId", inputCode, { expires: date })
                    changeActiveForm(invitationForm.guestTabForm)
                })
                .catch((response) => {
                    if (response.status === 404) {
                        setFormFeedBack(errorMessages.notfoundCode);
                    } else {
                        console.log(response);
                        setFormFeedBack(errorMessages.contactUsError);
                    }
                    setSubmitError(true)
                });

        }
    }

    return (
        <div className='formRespondNow'>
            <p className='labelTop'>{t("enterGuestCode")}</p>
            <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Control
                        required
                        placeholder={t("findCode")}
                        className='inputTextBox'
                        value={inputCode}
                        isInvalid={
                            (!InputValidator.alphanumerical(inputCode.length) && inputCode.length)
                            || submitError}
                        onChange={e => setInputCode(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formFeedBack}
                    </Form.Control.Feedback>
                    <Button className='buttonSubmit' disabled={!InputValidator.alphanumerical(inputCode) || !inputCode.length} type="submit">{t("submit")}</Button>
                </Form.Group>
            </Form>
            <Container className=""><p className='labelBot'>{t("noCode")}</p><Button onClick={() => changeActiveForm(invitationForm.searchByGuestNameForm)}>{t("searchByNameButton")}</Button></Container>
        </div>

    );

};

export default RespondNowForm;