/* eslint-disable no-useless-concat */
const BACKEND_URI = 'https://backend.nathalieandmehdi.com';
const API_KEY = 'ac2c6fd8-77de-4cf0-881b-d7b6613e80bf';

export const InvitationAPI = {
    async getInvitationById(invitationId) {
        const resp = await fetch(`${BACKEND_URI}` + '/invitations/' + invitationId, {
            headers: {
                'X-API-KEY': API_KEY
            }
        });
        return await getOrElseReject(resp);
    },
    async getAllGuest() {
        const resp = await fetch(`${BACKEND_URI}` + '/guests', {
            headers: {
                'X-API-KEY': API_KEY
            }
        });
        return await getOrElseReject(resp);
    },
    async updateInvitation(invitation) {
        const resp = await fetch(`${BACKEND_URI}` + '/invitations/' + invitation.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            },
            body: JSON.stringify(invitation)
        });
        return await getOrElseReject(resp);
    },
    async addGuest(id, guest) {
        guest.id = undefined;
        const resp = await fetch(`${BACKEND_URI}` + '/invitations/' + id + '/guest', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            },
            body: JSON.stringify(guest)
        });
        return await getOrElseReject(resp);
    }
}
/* eslint-enable no-useless-concat */

function getOrElseReject(resp) {
    if (resp.ok) {
        return resp.json();
    }
    return Promise.reject(resp);
}