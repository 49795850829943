import React from "react";
import '../../index.css'
import { Container, Row, Col } from "react-bootstrap";
import '../../index.css';
import { useTranslation } from "react-i18next";

const Planning = () => {
    const { t } = useTranslation();
    return (
        <div className="planning">
            <div className="planning-image-container"  ><img  className="planning-image"src="/images/colonne-fontdouce.jpg" alt="Planning" /></div>
            <Container fluid className="planning-list">
                <h1 style={{ fontWeight: 700 }}>{t('planning.title')}</h1>
                <Row className="planning-row row-1">
                    <Col className="planning-col">
                        <br />
                        <h2> <img className="planning-icon" src="/images/wedding-arch.png" alt="Ceremony" /> {t('planning.ceremony')}</h2>
                        <br />
                    </Col>
                </Row>
                <Row className="planning-row row-2">
                    <Col className="planning-col">
                        <h2> <img className="planning-icon" src="/images/champagne.png" alt="Reception" />{t('planning.reception')}</h2>
                        <br />
                    </Col>
                </Row>
                <Row className="planning-row row-3">
                    <Col className="planning-col">
                        <h2> <img className="planning-icon" src="/images/dinner.png" alt="Meal" /> {t('planning.meal')}</h2>
                        <br />
                    </Col>
                </Row>
                <Row className="planning-row row-4">
                    <Col className="planning-col">
                        <h2><img className="planning-icon" src="/images/dance.png" alt="Dancing" />{t('planning.dance')}</h2>
                    </Col>
                </Row>
                <Row className="planning-row row-5">
                    <Col className="planning-col">
                        <h2><img className="planning-icon" src="/images/croissant.png" alt="Brunch" />{t('planning.brunch')}</h2>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Planning;