import React from 'react';
import {  Row, Col } from 'react-bootstrap';
import { FaInstagram, FaFacebook, FaEnvelope, FaPhone } from 'react-icons/fa';
import '../../index.css';

const Footer = ({ respondNowFooter }) => {
  const footerClass = respondNowFooter ? `footer-respondNow` : 'footer';

  return (
    <footer className={footerClass}>
        <Row >
          <Col>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={30} className="social-icon" />
            </a>
          </Col>
          <Col>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={30} className="social-icon" />
            </a>
          </Col>
          <Col>
            <a href="mailto:example@example.com">
              <FaEnvelope size={30} className="social-icon" />
            </a>
          </Col>
          <Col>
            <a href="tel:+33610894021">
              <FaPhone size={30} className="social-icon" />
            </a>
          </Col>
        </Row>
    </footer>
  );
};

export default Footer;