import React, {useState} from "react";
import i18n from '../../internationalization/i18n';
import './LanguageSelector.css';
import { Dropdown, Form} from "react-bootstrap";;


const LanguageSelector = () => {
    const [countries] = useState([
        { code: 'eng'},
        { code: 'fr'},
      ]);
    var defaultCode = countries.find((code) => code === i18n.language) == null? "fr" :countries.find(i18n.language).code
    const [toggleContents, setToggleContents] = useState(defaultCode);

    const chooseLanguage = (eventKey) => {
          i18n.changeLanguage(eventKey); 
          setToggleContents(eventKey);

    }

    return (
        <Form>
        <Dropdown
          onSelect={chooseLanguage}
        >
          <Dropdown.Toggle
            variant="rimary"
            id="dropdown-flags"
          >
            {toggleContents}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {
                countries.map(({code}) => (
                    <Dropdown.Item className="dropdown-item" key={code} eventKey={code}>
                       {code}
                    </Dropdown.Item>
                ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </Form>
    );
};

export default LanguageSelector;