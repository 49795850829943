import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-scroll';
import './Navigation.css';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from "../internationalization/LanguageSelector";

const Navigation = ({backToHome}) => {
    const location = useLocation();
    const { t } = useTranslation();
    return (
        <Navbar collapseOnSelect expand="lg" className='navbar'>
            <Container fluid>
            <Navbar.Brand href="/" className='d-inline-block align-mid logo'> <img src="/images/couronne.png" alt="N&M" className="nav-brand-image" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Container fluid className="me-auto">
                        <Nav variant="underline" activeKey={location.pathname} className='navItem-main justify-content-end'>
                            
                            <Nav.Item>
                                <Link smooth={true} duration={250} className='navItems btn' onClick={backToHome}>{t("Home")}</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="planning" smooth={true} duration={250} className='navItems btn' onClick={backToHome}>{t("Planning")}</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="accommodation" smooth={true} duration={250} className='navItems btn' onClick={backToHome}>{t("Accommodation")}</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="activities" smooth={true} duration={250} className='navItems btn' onClick={backToHome}>{t("Activities")}</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="contribution" smooth={true} duration={250} className='navItems btn' onClick={backToHome}>{t("Contribution")}</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <LanguageSelector className='navItems' />
                            </Nav.Item>
                        </Nav>
                    </Container>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;


