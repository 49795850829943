import React from "react";
import ButtonResponse from "../core/button/ButtonResponse";
import { useTranslation } from 'react-i18next'
import CookieConsent from "react-cookie-consent";
import '../../index.css'

const Home = ({ onShowRespondNow}) => {
    const { t } = useTranslation();

    return (
        <div className="home" >
            <div className="home-content">
                <div className="home-title-container">
                    <img className="home-image-name" alt="" src="/images/name.png" />
                    <div className="home-subname-container">
                        <div className="address-container">
                            <p>
                                {t("wedding-date")}
                                <br />
                                <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x4800eff4ac3f8e91:0xa29f4456ac87eaaf?sa=X&ved=1t:8290&ictx=111">Abbaye de Fontdouce, 17770 Saint-Bris-des-Bois, France</a>
                            </p>

                            <ButtonResponse onClickButtonResponse={onShowRespondNow} buttonClass="main-response-button" />
                        </div>

                    </div>
                </div>
                <div className="home-image-container">
                    <img className="home-image-flower-left" alt="" src="/images/flower-long-inverse.png" />
                    <div className="image-container">
                        <img src="/images/background-fontdouce.png" alt="" className="main-image" />
                    </div>
                    <img className="home-image-flower-right" alt="" src="/images/flower-long.png" />
                </div>
            </div>
            <CookieConsent
                location="bottom"
                buttonText={t("buttonCookiesConsent")}
                style={{ background: "#cb6843", color: "black", fontSize: "20px" }}
                buttonStyle={{ background: "#cb6843", fontSize: "20px", color: "black", borderColor: "black", border: "2px solid black", borderRadius: "10px" }}
                expires={400}>
                {t("cookiesConsent")}
            </CookieConsent>
        </div>
    );

};

export default Home;