import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import '../../index.css';
import useIntersectionObserver from "../../internal/services/AutoScroller";
import { useTranslation } from "react-i18next";

const Accommodation = ({ isClickFromNavbar }) => {
    const { t } = useTranslation();
    const ref = useIntersectionObserver((element) => {

        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, null, isClickFromNavbar);

    return (
        <Container fluid className="accomodation accommodation-container" ref={ref}>
            <img className="accommodation-image-flower-top" alt="" src="/images/flower-arche.png" />
            <h1 className="text-center">{t("accomodationHeader")}</h1>
            <Row className="text-center justify-content-center">
                <p>{t("accommodationSubtitle")}</p>
            </Row>
            <Row className="justify-content-center">
                <Col md={4} className="d-flex justify-content-center">
                    <a href="https://www.airbnb.fr/s/Abbaye-de-Fontdouce--Saint~Bris~des~Bois--France/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-12-01&monthly_length=3&monthly_end_date=2025-03-01&price_filter_input_type=0&channel=EXPLORE&query=Abbaye%20de%20Fontdouce%2C%20Saint-Bris-des-Bois&place_id=ChIJkY4_rPTvAEgRr-qHrFZEn6I&location_bb=QjcVl77p0ypCNxLUvus0yg%3D%3D&date_picker_type=calendar&checkin=2025-06-21&checkout=2025-06-22&source=structured_search_input_header&search_type=autocomplete_click" target="_blank" rel="noopener noreferrer">
                        <img src="/images/airbnb-logo-0.png" alt={t("airbnbText")} className="img-fluid accommodation-logo" />
                    </a>
                </Col>
                <Col md={4} className="mb-3 d-flex justify-content-center">
                    <a href="https://www.abritel.fr/search?destination=Abbaye%20de%20Fontdouce%2C%20Saint-Bris-des-Bois%2C%20Charente-Maritime%20%28d%C3%A9partement%29%2C%20France&regionId=669669717554245632&latLong=45.769789%2C-0.458822&flexibility=0_DAY&d1=2025-06-21&startDate=2025-06-21&d2=2025-06-22&endDate=2025-06-22&adults=2&theme=&userIntent=&semdtl=&sort=RECOMMENDED" target="_blank" rel="noopener noreferrer">
                        <img src="/images/abritel.png" alt={t("abritelText")} className="img-fluid accommodation-logo" />
                    </a>
                </Col>
                <Col md={4} className="d-flex justify-content-center">
                    <a href="https://www.gites-de-france.com/fr/search?towns=42156&adults=2&children=0&infants=0&seed=239af7c0&arrival=2024-12-21&departure=2024-12-22&distance=10" target="_blank" rel="noopener noreferrer">
                        <img src="/images/gite-de-france.png" alt={t("giteDeFrance")} className="img-fluid accommodation-logo" />
                    </a>
                </Col>
            </Row>
            <img className="accommodation-image-flower-bottom" alt="" src="/images/flower-arche.png" />
        </Container>
    );
};

export default Accommodation;