import './ButtonResponse.css';

import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';


const ButtonResponse = ({ buttonClass, onClickButtonResponse }) => {

    const [, setCookie] = useCookies(['invitationId']);

    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const invitationId = searchParams.get("invitationId")
    const date = new Date();
    // TODO create a util for cookie expiration
    date.setDate(date.getDate() + 400);
    const repondNowButtonClick = () => {
        if (invitationId != null) {
            setCookie("invitationId", invitationId, { expires: date })
        }
        onClickButtonResponse()
    }

    return (
        <button onClick={repondNowButtonClick} className={buttonClass} >
            {t("Response")}
        </button>
    );
};

export default ButtonResponse;


