import Home from "./components/routes/Home";
import Planning from "./components/routes/Planning";
import Accommodation from "./components/routes/Accommodation";
import Activities from "./components/routes/Activities";
import Contribution from "./components/routes/Contribution";
import RespondNow from "./components/routes/RespondNow";
import Footer from './components/routes/Footer';
import { Element } from 'react-scroll';
import './App.css';
import { useState } from "react";
import Navigation from "./components/navigation/Navigation";


function App() {

  const [showRespondNow, setShowRespondNow] = useState(false);
  const [clikFromNavbar, setClickFromNavbar] = useState(false);

  const handleShowRespondNow = () => {
    setShowRespondNow(true);


  };

  const onClickOnNavItems = () => {
    setShowRespondNow(false);
    setClickFromNavbar(true);
  };

  //Used to ignore the avoid stop scrolling when clicking on the navbar
  var timer = null;
  document.addEventListener('scroll', function () {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      setClickFromNavbar(false);
    }, 250);
  }, false);

  return (
    <div>

      {showRespondNow && (
        <div>
        <div className="reponse-page" >
          <Navigation backToHome={onClickOnNavItems} />
          <div>
          <div className="respond-now-container" >
            <img src="/images/flower-long-inverse.png" className="respond-now-image-left" alt="N&M" />
            <Element name="home">
              <RespondNow />
            </Element>
            <img src="/images/flower-long.png" className="respond-now-image-right" alt="N&M" />
            
          </div>
        </div>
       
        </div>
        <Footer respondNowFooter={showRespondNow} />
        </div>
      )}

      {!showRespondNow && (
        <div>
          <div className="welcom-page" >
            <Navigation backToHome={onClickOnNavItems} />
            <Element name="home">
              <Home onShowRespondNow={handleShowRespondNow} />
            </Element>
          </div>
          <Element name="planning">
            <Planning className="planning" isClickFromNavbar={clikFromNavbar} />
          </Element>
          <Element name="accommodation">
            <Accommodation className="accommodation" isClickFromNavbar={clikFromNavbar} />
          </Element>
          <Activities className="activities" />
          <Element name="contribution">
            <Contribution className="contribution" isClickFromNavbar={clikFromNavbar} />
          </Element>
          <Footer respondNowFooter={showRespondNow} />
        </div>
      )}
    </div>


  );
}

export default App;
