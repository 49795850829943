import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import '../../index.css';
import useIntersectionObserver from "../../internal/services/AutoScroller";
import { useTranslation } from "react-i18next";

const Contribution = ({ isClickFromNavbar }) => {
    const { t } = useTranslation();
    const ref = useIntersectionObserver((element) => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, null, isClickFromNavbar);

    return (
        <Container fluid className="contribution" ref={ref}>
            <h1 className="text-center">{t('contribution.title')}</h1>
            <Row>
                <Col>
                    <Card className="contribution-card">
                        <Card.Body>
                            <div className="contribution-info-container">
                                <Card.Text>
                                    {t('contribution.description')}
                                 </Card.Text>
                                <img src="/images/mosaic.png" alt="contribution" className="contribution-image" />

                            </div>
                           
                            <Button className="contribution-button" variant="primary" href="https://www.leetchi.com/fr/c/nath-et-med-3000028?utm_source=copylink&utm_medium=social_sharing" target="_blank">
                                {t('contribution.buttonText')}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Contribution;