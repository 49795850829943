import { Container } from "react-bootstrap";
import RespondNowForm from "../core/form/RespondNowForm";
import SearchByGuestNameForm from "../core/form/SearchByGuestNameForm";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import GuestTabForm from "../core/form/GuestTabForm";
import EnterGuestInfoForm from "../core/form/EnterGuestInfoForm";
import InvitationContext from "../context/InvitationContext.js";
import { InvitationAPI } from "../../internal/services/InvitationAPI.js"
import { invitationForm } from "../../internal/utils/InvitationForms.js";

const invitationComponent = [RespondNowForm, SearchByGuestNameForm, GuestTabForm, EnterGuestInfoForm]


const RespondNow = () => {

    const [cookie] = useCookies(['invitationId']);
    const invitationId = cookie.invitationId;

    let initInvitation = [];

    let [ActiveForm, setForm] = useState(() => invitationComponent[invitationForm.searchByGuestNameForm]);
    const changeActiveForm = (formId) => {
        setForm(() => invitationComponent[formId]);
    }
    const [invitation, setInvitation] = useState(initInvitation)

    useEffect(() => {
        if (invitationId && invitation.length === 0) {
            InvitationAPI.getInvitationById(invitationId)
                .then((resp) => {
                    setInvitation(resp)
                    changeActiveForm(invitationForm.guestTabForm)
                }).catch((response) => {
                    if (response.status === 404) {
                        changeActiveForm(invitationForm.searchByGuestNameForm)
                    }
                    console.log(response.status, response.statusText);
                });
        }
    }, [invitationId, invitation.length]);

    let invitationState = {
        invitation: invitation,
        setInvitation: setInvitation
    }

    return (
        <InvitationContext.Provider value={invitationState}>
            <Container fluid className="home">
                <ActiveForm changeActiveForm={changeActiveForm} invitationId={invitationId} />
            </Container>
        </InvitationContext.Provider>

    );
};

export default RespondNow;