
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import InvitationContext from '../../context/InvitationContext.js';
import './CommonForm.css';
import { invitationForm } from '../../../internal/utils/InvitationForms.js';
import { useContext } from 'react';

const GuestTabForm = ({ changeActiveForm }) => {
    const { t } = useTranslation();

    const { invitation } = useContext(InvitationContext);

    const addAnonymousGuest = (anonymousGuestId) => {
        const refGuest = invitation.guests[0];
        invitation.guests.push({
            id: anonymousGuestId,
            firstName: "",
            lastName: "",
            civil: refGuest.civil,
            dinner: refGuest.dinner,
            brunch: refGuest.brunch,
            response: { validate: false }
        });
        invitation.activeGuestId = anonymousGuestId;
        changeActiveForm(invitationForm.enterGuestInfoForm)
    };

    const rows = [];
    //Reset active guest
    invitation.activeGuestId = null

    invitation.guests.forEach((guest) => {
        rows.push(
            <tr key={guest.id}>
                <td>{guest.firstName}</td>
                <td>{guest.lastName}</td>
                <td>{guest.response.validate === true ? t("done") : t("toBeDone")}</td>
                <td>
                    <Button className="response-button" onClick={() => {
                        invitation.activeGuestId = guest.id
                        changeActiveForm(invitationForm.enterGuestInfoForm)
                    }}>{guest.response.validate === true ? t("viewEditButton") : t("startButton")}
                    </Button>
                </td>
            </tr>
        )

    })

    if (rows.length < invitation.guestCount + invitation.guestAnonymousCount) {
        let availableSpot = invitation.guestCount + invitation.guestAnonymousCount - rows.length;
        for (let i = 0; i < availableSpot; i++) {

            rows.push(
                <tr key={"anonymous-" + i}>
                    <td colSpan="3">{t("addAnonymousGuestText")}</td>
                    <td><Button className="response-button" onClick={() => addAnonymousGuest("anonymous-" + i)}>{t("addGuestButton")}</Button></td>
                </tr>
            );
        }
    }
    return (
        <div className='formRespondNow'>
            <p className='labelTop'>{t("guestTabFormTitle")}</p>
            <table className='guestTable'>
                <thead>
                    <tr>
                        <th>{t("firstName")}</th>
                        <th>{t("lastName")}</th>
                        <th>{t("status")}</th>
                        <th>{t("actions")}</th>
                    </tr>
                </thead>
                <tbody className="guestTab-tbody">
                    {
                        rows
                    }
                </tbody>
            </table>
            <div><p className='labelBot'>{t("notYouLabel")}</p><Button className="response-button" onClick={() => changeActiveForm(invitationForm.searchByGuestNameForm)}>{t("changeGuestButton")}</Button></div>
        </div>

    );
};

export default GuestTabForm;