

const ALPHANUMERICAL = new RegExp("^[A-Za-z0-9]*$");

const MAX_SIZE = 10;
const MIN_SIZE = 4;

export const InputValidator = {
    alphanumerical(inputText) {
        return ALPHANUMERICAL.test(inputText);
    },

    isLenghtValid(codeLenght) {
        return codeLenght >= MIN_SIZE && codeLenght <= MAX_SIZE;
    }
}
