import { useEffect, useRef } from 'react';

const useIntersectionObserver = (callback, options, clickFromNavbar) => {
  const elementRef = useRef(null);

  useEffect(() => {

    const observer = new IntersectionObserver((entries) => {

      entries.forEach(entry => {
        if (entry.isIntersecting && !clickFromNavbar) {
          callback(entry.target);
        }
      });
    }, options);

    const element = elementRef.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [callback, options, clickFromNavbar]);

  return elementRef;
};

export default useIntersectionObserver;