import React from "react";
import { Container, Card, Carousel } from "react-bootstrap";
import '../../index.css';
import { useTranslation } from "react-i18next";

const Activities = () => {
    const { t } = useTranslation();

    return (
        <Container fluid className="activities" >
            <h1 className="activities-title">{t('activities.title')}</h1>
            <h2 className="activities-subtitle">{t('activities.subtitle')}</h2>
            <div className="actvite-carousel">
                <Carousel>
                    <Carousel.Item>
                        <Card className="activity-card">
                            <Card.Img variant="top" src="/images/activite-remy-martin.jpg" />
                            <Card.Body>
                                <Card.Title>

                                    <a href="https://www.destination-cognac.com/oenotourisme/le-cognac/visites-des-maisons-de-negoce-distilleries-et-proprietes-viticoles/" target="_blank" rel="noopener noreferrer">
                                        {t('activities.cognacDistilleries.title')}
                                    </a>
                                </Card.Title>
                                <Card.Text>
                                    {t('activities.cognacDistilleries.description')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Card className="activity-card">
                            <Card.Img variant="top" src="/images/charente.jpg" />
                            <Card.Body>
                                <Card.Title>
                                    <a href="https://www.destination-cognac.com/inspiration/carte-interactive/" target="_blank" rel="noopener noreferrer">
                                        {t('activities.charenteWalk.title')}
                                    </a>
                                </Card.Title>
                                <Card.Text>
                                    {t('activities.charenteWalk.description')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Card className="activity-card">
                            <Card.Img variant="top" src="/images/spa.jpg" />
                            <Card.Body>
                                <Card.Title>
                                    <a href="https://xeau.fr/espaces/bien-etre/" target="_blank" rel="noopener noreferrer">
                                        {t('activities.spa.title')}
                                    </a>
                                </Card.Title>
                                <Card.Text>
                                    {t('activities.spa.description')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="actvite-carousel">
                <h1 className="text-center">{t('food.title')}</h1>
                <Carousel>
                    <Carousel.Item>
                        <Card className="activity-card">
                            <Card.Img variant="top" src="/images/lemetayer.jpg" />
                            <Card.Body>
                                <Card.Title>
                                    <a href="https://maps.app.goo.gl/6Gt4pbscksrCwqF4A" target="_blank" rel="noopener noreferrer">
                                        {t('food.bestBakery.title')}
                                    </a>
                                </Card.Title>
                                <Card.Text>
                                    {t('food.bestBakery.description')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Card className="activity-card">
                            <Card.Img variant="top" src="/images/poulpet.jpg" />
                            <Card.Body>
                                <Card.Title>
                                    <a href="https://maps.app.goo.gl/pS3gZsJGq1xwcQPh7" target="_blank" rel="noopener noreferrer">
                                        {t('food.poulpette.title')}
                                    </a>
                                </Card.Title>
                                <Card.Text>
                                    {t('food.poulpette.description')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Card className="activity-card">
                            <Card.Img variant="top" src="/images/aristide.jpg" />
                            <Card.Body>
                                <Card.Title>
                                    <a href="https://maps.app.goo.gl/XvtwTXY5N5pAm98C7" target="_blank" rel="noopener noreferrer">
                                        {t('food.aristide.title')}
                                    </a>
                                </Card.Title>
                                <Card.Text>
                                    {t('food.aristide.description')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="actvite-carousel">
                <h1 className="text-center">{t('drinks.title')}</h1>
                <Carousel>
                    <Carousel.Item>
                        <Card className="activity-card">
                            <Card.Img variant="top" src="/images/indigo.jpg" />
                            <Card.Body>
                                <Card.Title>
                                    <a href="https://maps.app.goo.gl/kRejHwpe2Qx2d3hy6" target="_blank" rel="noopener noreferrer">
                                        {t('drinks.martel.title')}
                                    </a>
                                </Card.Title>
                                <Card.Text>
                                    {t('drinks.martel.description')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Card className="activity-card">
                            <Card.Img variant="top" src="/images/ponti.jpg" />
                            <Card.Body>
                                <Card.Title>
                                    <a href="https://maps.app.goo.gl/dN4tx9NURBUnRqHeA" target="_blank" rel="noopener noreferrer">
                                        {t('drinks.quaiDesPontis.title')}
                                    </a>
                                </Card.Title>
                                <Card.Text>
                                    {t('drinks.quaiDesPontis.description')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Card className="activity-card">
                            <Card.Img variant="top" src="/images/origins.jpg" />
                            <Card.Body>
                                <Card.Title>
                                    <a href="https://maps.app.goo.gl/Qay6KFLactimWZieA" target="_blank" rel="noopener noreferrer">
                                        {t('drinks.origin.title')}
                                    </a>
                                </Card.Title>
                                <Card.Text>
                                    {t('drinks.origin.description')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div>
                <p className="activities-other">
                    {t('activities.other.title')}
                    <a href="https://maps.app.goo.gl/Qay6KFLactimWZieA" target="_blank" rel="noopener noreferrer">
                    Cognac</a></p>
            </div>
        </Container>
    );
};

export default Activities;